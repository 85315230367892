import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ComponentCode name="Tooltip" component="tooltip" variation="tooltip" hasReactVersion hasVueVersion="tooltip--default-interactive-tootlip" hasAngularVersion="?path=/story/tooltip--basic" codepen="OGNWpy" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Icon tooltip" component="tooltip" variation="tooltip--icon" hasReactVersion hasVueVersion="tooltip--default-tootlip" codepen="LvNxWq" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentCode name="Definition tooltip" component="tooltip" variation="tooltip--definition" hasReactVersion hasVueVersion="tooltip--default-definition-tootlip" codepen="pByRPr" mdxType="ComponentCode">
    </ComponentCode>
    <ComponentDocs component="tooltip" mdxType="ComponentDocs" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      